body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: "#F2F2F2";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.DraftEditor-root {
    width: "100%" !important;
}

.public-DraftEditor-content {
    overflow-wrap: anywhere !important;
}

.MuiSelect-root {
    background-color: white !important;
}

.makeStyles-scrollBar-46 .infinite-scroll-component__outerdiv {
    width: 100%;
}

.infinite-scroll-component {
    overflow: hidden !important;
}
